import { trackEvent } from "./src/plausible";

export function shouldUpdateScroll({ prevRouterProps, routerProps }) {
  const preventScroll =
    new URLSearchParams(routerProps.location.search).get("prsc") !== "true";

  return preventScroll;
}

export function onRouteUpdate({ location }) {
  if (location.hash === "#get-in-touch") {
    trackEvent("Get In Touch button clicked", {
      props: {
        "get-in-touch-button-page-title": location.pathname,
      },
    });
  }
}